
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import Router from 'next/router';
import App, {AppContext, AppInitialProps, AppProps} from 'next/app'
import NProgress from 'nprogress';
import '../public/css/rc-slider.css';
import '../public/css/ngprogress.css';
import '../public/css/custom.css';
import '../public/css/burger.css';
import '../public/css/navmenu.css';
import '../public/css/lightbox.css';
import {ManagedUIContext} from "@components/ui/context";
import {FC, useEffect} from "react";
import {CommerceProvider} from "@framework/index";
import Script from "next/script";


Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const Noop: FC = ({ children }) => <>{children}</>

function MyApp({ Component, pageProps }: AppProps) {
    const Layout = (Component as any).Layout || Noop

    useEffect(() => {
        let height = window.innerHeight;
        let splashScreen = document.getElementsByClassName("splashscreen")[0] as any;
        splashScreen.style["overflow"] = "hidden";
        splashScreen.style["height"] = height + "px";
        splashScreen.style["padding-top"] = "0px";
        splashScreen.style["padding-bottom"] = "0px";
        splashScreen.style["margin-top"] = "0px";
        splashScreen.style["margin-bottom"] = "0px";


        let interval = setInterval(function () {
            height -= 10;
            splashScreen.style["height"] = height + "px";
            if(height < 40 ) {
                splashScreen.style["display"] = "none";
                clearInterval(interval);
            }
        }, 1);

        //document.body.classList?.remove('loading')

       // $(".splashscreen").slideUp(300);
    }, [])

  return (
          <CommerceProvider>
            <ManagedUIContext>
                <Layout pageProps={pageProps}>
                    <Component {...pageProps} />
                    <Script id="show-banner">
                        {`
(function() {
    window.ybug_settings = {"id":"g2dqc1r1mt4878vct3na"};
    var ybug = document.createElement('script'); ybug.type = 'text/javascript'; ybug.async = true;
    ybug.src = 'https://widget.ybug.io/button/'+window.ybug_settings.id+'.js';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ybug, s);
})();`}
                    </Script>
                </Layout>
            </ManagedUIContext>
          </CommerceProvider>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  