import {Fetcher} from '@commerce/utils/types'
import {FetcherError} from '@commerce/utils/errors'
import Cookies from "js-cookie";
import {COOKIE_NAME, CUSTOMER_COOKIE} from "@framework/const";
import {useRouter} from "next/router";


async function getText(res: Response) {
    try {
        return (await res.text()) || res.statusText
    } catch (error) {
        return res.statusText
    }
}

async function getError(res: Response) {
    if (res.headers.get('Content-Type')?.includes('application/json')) {
        const data = await res.json()
        return new FetcherError({errors: data.errors, status: res.status})
    }
    return new FetcherError({message: await getText(res), status: res.status})
}


export const fetcher: Fetcher = async ({
                                           url,
                                           locale,
                                           query = [],
                                           method = 'GET',
                                           variables,
                                           headers,
                                           body: bodyObj,
                                       }) => {

    const hasBody = Boolean(variables || bodyObj)
    const body = hasBody
        ? JSON.stringify(variables ? {variables} : bodyObj)
        : undefined
    // headers = hasBody ? {'Content-Type': 'application/json'} : undefined
    if (hasBody) {
        headers = {...headers, 'Content-Type': 'application/json'};
    }

    if (locale) {
        query["lang"] = locale
    }
    if(url === '/cart/') {
        url = url + (Cookies.get(COOKIE_NAME) !== undefined ? Cookies.get(COOKIE_NAME) : "")
        const customerToken = Cookies.get(CUSTOMER_COOKIE) ? Cookies.get(CUSTOMER_COOKIE) : '';
        headers = {...headers, 'Authorization': 'Bearer ' + customerToken }
        if( window.location.pathname.split('/')[1] == 'en') {
            query["lang"] = 'en'
        } else {
            query["lang"] = 'cs'
        }
    }

    const qs = Object.keys(query)
        .map((key) => {
            if(Array.isArray(query[key])) {
                const pom: any[] = [];
                query[key].map((val: any) => pom.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`));
                return pom.join('&');
            } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
            }
        })
        .join('&');

   // console.log(`${process.env.API_SERVER}/v1${url!}${qs ? '?' + qs : ''}`);
    const res = await fetch(`${process.env.API_SERVER}/v1${url!}${qs ? '?' + qs : ''}`, {method, body, headers})

    if (res.ok) {
        if(res.status === 200 || res.status === 201) {
            return await res.json()
        }else {
            return;
        }
    }
    throw res
}
