import { fetcher } from './fetcher'
import { handler as useCart } from './cart/use-cart'
import { handler as useAddItem } from './cart/use-add-item'
import { handler as useRemoveItem } from './cart/use-delete-item'
import { handler as useLogin } from './auth/use-login'
import { handler as useCustomer } from './customer/use-customer'
import { handler as useLogout } from './auth/use-logout'

import { handler as useMenu } from './menu/use-menu'
import { handler as useWishlist } from './wishlist/use-wishlist'
import { handler as useAddWishlist } from './wishlist/use-add-item'
import { handler as useRemoveWishlistItem } from './wishlist/use-remove-item'

import { handler as useSettings } from './settings/use-settings'

//import { handler as useUpdateItem } from './cart/use-update-item'
//import { handler as useRemoveItem } from './cart/use-remove-item'

export const localProvider = {
  locale: 'en-us',
  fetcher: fetcher,
  cartCookie: 'session',
  cart: { useCart, useAddItem, useRemoveItem },
  wishlist: { useWishlist, useAddWishlist, useRemoveWishlistItem },
  customer: { useCustomer },
  settings: { useSettings },
  menu: { useMenu},
  //products: { useSearch },
  auth: { useLogin, useLogout },
}

export type LocalProvider = typeof localProvider
