import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import useLogout, { UseLogout } from '@commerce/auth/use-logout'
import type { LogoutHook } from '@commerce/types/logout'
import useCustomer from '../customer/use-customer'
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

export default useLogout as UseLogout<typeof handler>

export const handler: MutationHook<LogoutHook> = {
    fetchOptions: {
        url: '/customers/me',
        method: 'GET',
    },
    useHook:
        ({ fetch }) =>
            () => {
                const { mutate } = useCustomer()

                return useCallback(
                    async function logout() {
                        Cookies.remove(CUSTOMER_COOKIE)
                        const data = await fetch()
                        await mutate(null, false)
                        return data
                    },
                    [fetch, mutate]
                )
            },
}