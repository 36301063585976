import {SWRHook} from '@commerce/utils/types'
import useSettings , { UseSettings } from '@commerce/settings/use-settings'
import {SettingsHook} from "@commerce/types/settings";

export default useSettings as UseSettings<typeof handler>

export const handler: SWRHook<SettingsHook> = {
    fetchOptions: {
        url: '/settings',
        method: 'GET',
    },
    async fetcher({ options, fetch }) {
        try {
            return await fetch({...options });
        } catch (e) {
            return null;
        }
    },
    useHook:
        ({ useData }) =>
            (input) => {
                return useData({
                    swrOptions: {
                        revalidateOnFocus: true,
                        ...input?.swrOptions,
                    },
                })
            },
}
