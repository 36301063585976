import { useMemo } from 'react'
import { SWRHook } from '@commerce/utils/types'
import useWishlist , { UseWishlist } from '@commerce/wishlist/use-wishlist'
import type { GetWishlistHook } from '@commerce/types/wishlist'
// import useCustomer from '../customer/use-customer'
import Cookies from "js-cookie";
import {TOKEN_COOKIE} from "@commerce/constants";
import {CUSTOMER_COOKIE} from "@framework/const";

export default useWishlist as UseWishlist<typeof handler>

export const handler: SWRHook<GetWishlistHook> = {
    fetchOptions: {
        url: '/my-account/favorites',
        method: 'GET',
    },
    async fetcher({ input: { customerToken, locale }, options, fetch }) {
        if (!customerToken) return null

        return fetch({
            url: options.url + '?lang=' + locale,
            method: options.method,
            headers: {
                'Authorization': 'Bearer ' + customerToken,
            }
        })
    },
    useHook:
        ({ useData }) =>
            (input) => {
                //const { data: customer } = useCustomer()
                const response = useData({
                    input: [
                        ['locale', input?.locale],
                        ['customerToken', Cookies.get(CUSTOMER_COOKIE)],
                        ['includeProducts', input?.includeProducts],
                    ],
                    swrOptions: {
                        revalidateOnFocus: true,
                        ...input?.swrOptions,
                    },
                })

                return useMemo(
                    () =>
                        Object.create(response, {
                            isEmpty: {
                                get() {
                                    return (response.data?.items?.length || 0) <= 0
                                },
                                enumerable: true,
                            },
                        }),
                    [response]
                )
            },
}