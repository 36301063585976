import {SWRHook} from '@commerce/utils/types'
import useMenu, { UseMenu } from '@commerce/menu/use-menu'
import {MenuHook} from "@commerce/types/menu";

export default useMenu as UseMenu<typeof handler>

export const handler: SWRHook<MenuHook> = {
    fetchOptions: {
        url: '/menu',
        method: 'GET',
    },
    async fetcher({ options, fetch }) {
        return await fetch({...options })
    },
    useHook:
        ({ useData }) =>
            (input) => {
                return useData({
                    swrOptions: {
                        revalidateOnFocus: false,
                        ...input?.swrOptions,
                    },
                })
            },
}
