import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import { CommerceError } from '@commerce/utils/errors'
import useAddItem, { UseAddItem } from '@commerce/wishlist/use-add-item'
import type { AddItemHook } from '@commerce/types/wishlist'
import useCustomer from '../customer/use-customer'
import useWishlist from './use-wishlist'
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";
import {useRouter} from "next/router";

export default useAddItem as UseAddItem<typeof handler>


export const handler: MutationHook<AddItemHook> = {
  fetchOptions: {
    url: '/my-account/favorites',
    method: 'POST',
  },
    async fetcher({ input: { customerToken, productId, }, options, fetch }) {

        return fetch({
            url: options.url,
            method: options.method,
            body: {
                productId
            },
            headers: {
                'Authorization': 'Bearer ' + customerToken,
            }
        })
    },
  useHook:
    ({ fetch }) =>
    () => {
      //const { data: customer } = useCustomer()
      const customer = null;
      const router = useRouter();
      const { mutate } = useWishlist({ locale: router.locale! })

      return useCallback(
        async function addItem(item) {
          /*if (!customer) {
            // A signed customer is required in order to have a wishlist
            throw new CommerceError({
              message: 'Signed customer not found',
            })
          }*/

          // TODO: add validations before doing the fetch
          const data = await fetch({ input: { customerToken: Cookies.get(CUSTOMER_COOKIE)!, productId: item.productId } })
          await mutate()
          return data
        },
        [fetch, mutate, customer]
      )
    },
}
