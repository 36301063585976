import { useHook, useSWRHook } from '../utils/use-hook'
import { SWRFetcher } from '../utils/default-fetcher'
import type { HookFetcherFn, SWRHook } from '../utils/types'
import type { Provider } from '..'
import {SettingsHook} from "@commerce/types/settings";

export type UseSettings<
    H extends SWRHook<SettingsHook<any>> = SWRHook<SettingsHook>
    > = ReturnType<H['useHook']>

export const fetcher: HookFetcherFn<SettingsHook> = SWRFetcher

const fn = (provider: Provider) => provider.settings?.useSettings!

const useSettings: UseSettings = (input) => {
    const hook = useHook(fn)
    return useSWRHook({ fetcher, ...hook })(input)
}

export default useSettings
