import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import { CommerceError } from '@commerce/utils/errors'
import useRemoveItem , { UseRemoveItem } from '@commerce/cart/use-remove-item'
import type { RemoveItemHook } from '@commerce/types/cart'
import useCart from './use-cart'
import Cookies from 'js-cookie'
import {COOKIE_NAME} from "@framework/const";
import {useRouter} from "next/router";

export default useRemoveItem as UseRemoveItem<typeof handler>

export const handler: MutationHook<RemoveItemHook> = {
    fetchOptions: {
        url: '/cart/',
        //query: '/cart/',
        method: 'DELETE',
    },
    async fetcher({ input, options, fetch }) {
        const data = await fetch({
            url: options.url,
            // query: options.query,
            method: options.method,
            query: {itemId: input.itemId, variantId: input.variantId },
        })
        return data
    },
    useHook: ({ fetch }) =>
        function useHook() {
            const { mutate } = useCart()
            return useCallback(
                async function removeItem(input) {
                    const cart = await fetch({ input: { itemId: input.id, variantId: input.variantId } })
                    await mutate(cart, false)
                    return cart
                },
                [mutate]
            )
        },
}
