import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import {CommerceError, MaximumCapacity} from '@commerce/utils/errors'
import useAddItem, { UseAddItem } from '@commerce/cart/use-add-item'
import type { AddItemHook } from '@commerce/types/cart'
import useCart from './use-cart'
import Cookies from 'js-cookie'
import {COOKIE_NAME} from "@framework/const";

export default useAddItem as UseAddItem<typeof handler>

export const handler: MutationHook<AddItemHook> = {
    fetchOptions: {
        url: '/cart/',
        method: 'POST',
    },
    async fetcher({  input: item, options, fetch }) {
        if (
            item.quantity &&
            (!Number.isInteger(item.quantity) || item.quantity! < 1)
        ) {
            throw new CommerceError({
                message: 'The item quantity has to be a valid integer greater than 0',
            })
        }

        try {
            const data = await fetch({
                ...options,
                body: item,
            })
            Cookies.set(COOKIE_NAME, data.id);
            return data
        } catch (e) {
            throw new MaximumCapacity({
                message: 'Maximum capacity',
            })
        }
    },
    useHook:
        ({ fetch }) =>
            () => {
                const { mutate } = useCart()

                return useCallback(
                    async function addItem(input) {
                        const data = await fetch({ input })
                        await mutate(data, false)
                        return data
                    },
                    [fetch, mutate]
                )
            },
}
