import React, { FC, useCallback, useMemo } from 'react'
import { ThemeProvider } from 'next-themes'

export interface State {
    displaySidebar: boolean
    displayDropdown: boolean
    displayModal: boolean
    sidebarView: string
    modalView: string
    userAvatar: string
    filter: any[];
    filterPrice: { min: number, max: number }
    hugoUrl: string,
    hugoProductId: string,
    productId: string,
    variantId: string | null,
    typeModal: string,
    giftColor: string,
    productVariants: {},
    selectedProductVariant: {},
    productInfo: {},
}

const initialState = {
    displaySidebar: false,
    displayDropdown: false,
    displayModal: false,
    modalView: 'LOGIN_VIEW',
    sidebarView: 'CART_VIEW',
    filterPrice: { min: 1, max: 10000 },
    userAvatar: '',
    filter: [],
    deliveryPoint: {},
    hugoUrl: '',
    hugoProductId: '',
    stockId: '',
    productId: '',
    variantId: null,
    typeModal: '',
    giftColor: '',
    productVariants: {},
    selectedProductVariant: {},
    productInfo: {}
}

type Action =
    | {
    type: 'OPEN_SIDEBAR'
}
    | {
    type: 'CLOSE_SIDEBAR'
}
    | {
    type: 'OPEN_DROPDOWN'
}
    | {
    type: 'CLOSE_DROPDOWN'
}
    | {
    type: 'OPEN_MODAL'
}
    | {
    type: 'CLOSE_MODAL'
}
    | {
    type: 'SET_MODAL_VIEW'
    value: MODAL_VIEWS
} | {
    type: 'SET_TYPE_MODAL'
    value: string,
}
    | {
    type: 'SET_SIDEBAR_VIEW'
    view: SIDEBAR_VIEWS
}
    | {
    type: 'SET_USER_AVATAR'
    value: string
} | {
    type: 'SET_FILTER_PRICE'
    value: { min: number, max: number }
} | {
    type: 'SET_FILTER'
    value: []
} | {
    type: 'SET_DELIVERY_POINT'
    value: {}
} | {
    type: 'SET_HUGO_URL'
    value: string
} | {
    type: 'SET_GIFT_COLOR'
    value: string
} | {
    type: 'SET_HUGO_PRODUCT_ID'
    value: string
} | {
    type: 'SET_PRODUCT_ID'
    value: string
} | {
    type: 'SET_VARIANT_ID'
    value: string
} | {
    type: 'SET_STOCK_ID'
    value: string
} | {
    type: 'SET_PRODUCT_VARIANTS'
    value: any[]
} | {
    type: 'SET_SELECTED_PRODUCT_VARIANT'
    value: any[]
} | {
    type: 'SET_SELECTED_PRODUCT',
    value: {}
}

type MODAL_VIEWS =
    | 'HUGO'
    | 'SIGNUP_VIEW'
    | 'LOGIN_VIEW'
    | 'FORGOT_VIEW'
    | 'NEW_SHIPPING_ADDRESS'
    | 'NEW_PAYMENT_METHOD'
    | 'CP_BALIKOVNA'

type SIDEBAR_VIEWS = 'CART_VIEW' | 'CHECKOUT_VIEW' | 'PAYMENT_METHOD_VIEW'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
    switch (action.type) {
        case 'OPEN_SIDEBAR': {
            return {
                ...state,
                displaySidebar: true,
            }
        }
        case 'CLOSE_SIDEBAR': {
            return {
                ...state,
                displaySidebar: false,
            }
        }
        case 'OPEN_DROPDOWN': {
            return {
                ...state,
                displayDropdown: true,
            }
        }
        case 'CLOSE_DROPDOWN': {
            return {
                ...state,
                displayDropdown: false,
            }
        }
        case 'OPEN_MODAL': {
            return {
                ...state,
                displayModal: true,
                displaySidebar: false,
            }
        }
        case 'CLOSE_MODAL': {
            return {
                ...state,
                displayModal: false,
            }
        }
        case 'SET_MODAL_VIEW': {
            return {
                ...state,
                modalView: action.value,
            }
        }
        case 'SET_TYPE_MODAL': {
            return {
                ...state,
                typeModal: action.value,
            }
        }
        case 'SET_SIDEBAR_VIEW': {
            return {
                ...state,
                sidebarView: action.view,
            }
        }
        case 'SET_FILTER_PRICE': {
            return {
                ...state,
                filterPrice: action.value,
            }
        }
        case 'SET_USER_AVATAR': {
            return {
                ...state,
                userAvatar: action.value,
            }
        }
        case 'SET_FILTER': {
            return {
                ...state,
                filter: action.value,
            }
        }
        case 'SET_DELIVERY_POINT': {
            return {
                ...state,
                deliveryPoint: action.value,
            }
        }
        case 'SET_HUGO_URL': {
            return {
                ...state,
                hugoUrl: action.value,
            }
        }
        case 'SET_HUGO_PRODUCT_ID': {
            return {
                ...state,
                hugoProductId: action.value,
            }
        }
        case 'SET_PRODUCT_ID': {
            return {
                ...state,
                productId: action.value,
            }
        }
        case 'SET_VARIANT_ID': {
            return {
                ...state,
                variantId: action.value,
            }
        }
        case 'SET_GIFT_COLOR': {
            return {
                ...state,
                giftColor: action.value,
            }
        }
        case 'SET_PRODUCT_VARIANTS': {
            return {
                ...state,
                productVariants: action.value,
            }
        }
        case 'SET_SELECTED_PRODUCT_VARIANT': {
            return {
                ...state,
                selectedProductVariant: action.value,
            }
        }
        case 'SET_STOCK_ID': {
            return {
                ...state,
                stockId: action.value,
            }
        }
        case 'SET_SELECTED_PRODUCT': {
            return {
                ...state,
                productInfo: action.value,
            }
        }
    }
}

export const UIProvider: FC = (props) => {
    const [state, dispatch] = React.useReducer(uiReducer, initialState)

    const openSidebar = useCallback(
        () => dispatch({ type: 'OPEN_SIDEBAR' }),
        [dispatch]
    )
    const closeSidebar = useCallback(
        () => dispatch({ type: 'CLOSE_SIDEBAR' }),
        [dispatch]
    )
    const toggleSidebar = useCallback(
        () =>
            state.displaySidebar
                ? dispatch({ type: 'CLOSE_SIDEBAR' })
                : dispatch({ type: 'OPEN_SIDEBAR' }),
        [dispatch, state.displaySidebar]
    )
    const closeSidebarIfPresent = useCallback(
        () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
        [dispatch, state.displaySidebar]
    )

    const openDropdown = useCallback(
        () => dispatch({ type: 'OPEN_DROPDOWN' }),
        [dispatch]
    )
    const closeDropdown = useCallback(
        () => dispatch({ type: 'CLOSE_DROPDOWN' }),
        [dispatch]
    )

    const openModal = useCallback(
        () => dispatch({ type: 'OPEN_MODAL' }),
        [dispatch]
    )
    const closeModal = useCallback(
        () => dispatch({ type: 'CLOSE_MODAL' }),
        [dispatch]
    )

    const setUserAvatar = useCallback(
        (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
        [dispatch]
    )

    const setHugoUrl = useCallback(
        (value: string) => dispatch({ type: 'SET_HUGO_URL', value }),
        [dispatch]
    )

    const setHugoProductId = useCallback(
        (value: string) => dispatch({ type: 'SET_HUGO_PRODUCT_ID', value }),
        [dispatch]
    )

    const setProductId = useCallback(
        (value: string) => dispatch({ type: 'SET_PRODUCT_ID', value }),
        [dispatch]
    )

    const setVariantId = useCallback(
        (value: string) => dispatch({ type: 'SET_VARIANT_ID', value }),
        [dispatch]
    )

    const setFilter = useCallback(
        (value: []) => dispatch({ type: 'SET_FILTER', value }),
        [dispatch]
    )

    const setDeliveryPoint = useCallback(
        (value: []) => dispatch({ type: 'SET_DELIVERY_POINT', value }),
        [dispatch]
    )

    const setModalView = useCallback(
        (value: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', value }),
        [dispatch]
    )

    const setSidebarView = useCallback(
        (view: SIDEBAR_VIEWS) => dispatch({ type: 'SET_SIDEBAR_VIEW', view }),
        [dispatch]
    )

    const setFilterPrice = useCallback(
        (value: { min: number, max: number }) => dispatch({ type: 'SET_FILTER_PRICE', value }),
        [dispatch]
    )

    const setTypeModal = useCallback(
        (value: string) => dispatch({ type: 'SET_TYPE_MODAL', value }),
        [dispatch]
    )

    const setGiftColor = useCallback(
        (value: string) => dispatch({ type: 'SET_GIFT_COLOR', value }),
        [dispatch]
    )

    const setProductVariants = useCallback(
        (value: any) => dispatch({ type: 'SET_PRODUCT_VARIANTS', value }),
        [dispatch]
    )

    const setStockId = useCallback(
        (value: any) => dispatch({ type: 'SET_STOCK_ID', value }),
        [dispatch]
    )

    const setSelectedProductVariant = useCallback(
        (value: any) => dispatch({ type: 'SET_SELECTED_PRODUCT_VARIANT', value }),
        [dispatch]
    )
    const setProductInfo = useCallback(
        (value: any) => dispatch({ type: 'SET_SELECTED_PRODUCT', value }),
        [dispatch]
    )


    const value = useMemo(
        () => ({
            ...state,
            openSidebar,
            closeSidebar,
            toggleSidebar,
            closeSidebarIfPresent,
            openDropdown,
            closeDropdown,
            openModal,
            closeModal,
            setModalView,
            setSidebarView,
            setUserAvatar,
            setFilter,
            setHugoUrl,
            setHugoProductId,
            setTypeModal,
            setGiftColor,
            setProductVariants,
            setSelectedProductVariant,
            setDeliveryPoint,
            setProductId,
            setVariantId,
            setStockId,
            setProductInfo,
            setFilterPrice,
        }),
        [state]
    )

    return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
    const context = React.useContext(UIContext)
    if (context === undefined) {
        throw new Error(`useUI must be used within a UIProvider`)
    }
    return context
}

export const ManagedUIContext: FC = ({ children }) => (
    <UIProvider>
        {children}
    </UIProvider>
)
