import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import {LoginHook} from "@commerce/types/login";
import {HookFetcherFn} from "@commerce/utils/types";
import {mutationFetcher} from "@commerce/utils/default-fetcher";
import {Provider} from "@commerce";
import {useHook, useMutationHook} from "@commerce/utils/use-hook";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";
import useCustomer from '../customer/use-customer'


type UseLogin<H extends MutationHook<LoginHook<any>> = MutationHook<LoginHook>> = ReturnType<H['useHook']>

const fetcher: HookFetcherFn<LoginHook> = mutationFetcher
const useLogin: UseLogin = (...args) => {
    const hook = useHook((provider: Provider) => provider.auth?.useLogin!)
    return useMutationHook({ fetcher, ...hook })(...args)
}

export default useLogin as UseLogin<typeof handler>

export const handler: MutationHook<LoginHook> = {
    fetchOptions: {
        url: '/oauth',
        method: 'POST',
    },
    async fetcher({ input: item, options, fetch }) {

        const data = await fetch({
            ...options,
            body: { email: item.email, password: item.password },
        })

        Cookies.set(CUSTOMER_COOKIE, data.token);
        return null;
    },
    useHook:
        ({ fetch }) =>
            () => {
                const { mutate } = useCustomer()
                return useCallback(
                    async function useLogin(input) {
                        const data = await fetch({ input })
                        await mutate();
                        return data;
                    },
                    [fetch, mutate]
                )
            },
}
