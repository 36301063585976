import {MutationHook, SWRHook} from '@commerce/utils/types'
import useCustomer, { UseCustomer } from '@commerce/customer/use-customer'
import {CustomerHook} from "@commerce/types/customer";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

export default useCustomer as UseCustomer<typeof handler>

export const handler: SWRHook<CustomerHook> = {
    fetchOptions: {
        url: '/customers/me',
        method: 'GET',
    },
    async fetcher({ options, fetch }) {
       // const token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyVVVJRCI6Ijk3ZGFmMjcyLTcyMmMtMTFlYy1iODVlLWFjZGU0ODAwMTEyMiIsImlzU2Vjb25kRmFjdG9yQXV0aGVudGljYXRlZCI6ZmFsc2UsInBlcm1pc3Npb25zIjpbImNyZWF0ZTphdmFpbGFiaWxpdHkiXSwiaWF0IjoxNjQyMDY4Nzg4LCJleHAiOjE2NDIxNTQ3ODh9.Os5h_L9eTl36cJGEtpBeUeo0ihQlfjEDoUIv_Q_hJj6pZsmBtPsE7zA3YVyrvN_DAZmUjriW8w6zXDYDRVreog";
        console.log("DATA");
        const token = Cookies.get(CUSTOMER_COOKIE)
        if (!token) {
           return null;
        }

        const headers = {headers:  {
                'Authorization': 'Bearer ' + token
            }};

        // const decodedToken = decode(token) as { cid: string }
        try {
            return await fetch({...options, ...headers});
        } catch (e) {
            return null;
        }
        // return customer
    },
    useHook:
        ({ useData }) =>
            (input) => {
                return useData({
                    swrOptions: {
                        revalidateOnFocus: true,
                        ...input?.swrOptions,
                    },
                })
            },
}
